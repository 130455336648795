
export const BORDER_WIDTH = 150;
export const BORDER_HEIGHT = 150;
export const CELL_ROWS = 8;
export const CELL_COLUMN = 16;
export const CELL_W = 80;
export const CELL_H = 80;
export const CELL_SPACE_W = 8;
export const CELL_SPACE_H = 8;


