import React from 'react';
import {CELL_H, CELL_SPACE_H, CELL_SPACE_W, CELL_W} from "../config";

class Cell extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            x: props.x,
            y: props.y,
            w: props.w || 1,
            h: props.h || 1,
        }
    }

    getRect() {
        let left = this.state.x * (CELL_W + CELL_SPACE_W);
        let top = this.state.y * (CELL_H + CELL_SPACE_H);
        let width = this.state.w * (CELL_W + CELL_SPACE_W) - CELL_SPACE_W;
        let height = this.state.h * (CELL_H + CELL_SPACE_H) - CELL_SPACE_H;
        return {left, top, width, height};
    }

    render() {
        let rect = this.getRect();
        return (
            <div className="cell" style={{ left: rect.left, top: rect.top, width: rect.width, height: rect.height}}>
            </div>
        )
    }
}

export default Cell;