import Cell from './Cell'
import React from "react";
import {Icon, Tabs, Input } from "antd";

const { TabPane } = Tabs;
const { Search } = Input;


class SearchTile extends Cell {
    constructor(props) {
        super(props);
        this.state = {
            x: props.x,
            y: props.y,
            w: props.w || 4,
            h: props.h || 2,
            engine: props.engine,
            activePage: 0,
            keyword: "",
        }
    }

    render() {
        let rect = this.getRect();
        let panes = [];
        for(let i = 0; i < this.state.engine.length; i++) {
            let data = this.state.engine[i];
            panes.push(
                <TabPane key={i} tab={<span>{data.name}</span>} >
                    <div style={{textAlign: "center"}}>
                    <a target="_blank" rel="noopener noreferrer" href={data.domain}>
                        <img className="search-logo" src={data.logo} alt="logo"/>
                    </a>
                    </div>
                </TabPane>
            );
        }
        return (
            <div
                className="search-tile"
                style={{
                    left: rect.left,
                    top: rect.top,
                    width: rect.width,
                    height: rect.height,
                    backgroundColor: this.state.color,
                }}
            >
                <Tabs
                    defaultActiveKey="0"
                    size="small"
                    onChange={(activeKey) => {this.setState({activePage: activeKey})}}
                >
                    {panes}
                </Tabs>
                <Search
                    prefix={<Icon type="search" style={{ color: 'rgba(0,0,0,.25)' }} />}
                    placeholder="input search text"
                    enterButton="Search"
                    maxLength={255}
                    onChange={(e) =>{ this.setState({keyword:e.target.value})}}
                    onSearch={value => document.getElementById("search_form").submit()}
                    style={{Opacity: 1, padding: "0px 15px 0px 15px"}}
                />
                <form hidden id="search_form" action={this.state.engine[this.state.activePage].url} target="_blank">
                    <input name={this.state.engine[this.state.activePage].kw} value={this.state.keyword} onChange={v => {}}/>
                </form>
            </div>
        )
    }
}

export default SearchTile;