import React from 'react';
import 'antd/dist/antd.css';
import './App.css';
import Cell from './components/Cell'
import Tile from './components/Tile'
import SearchTile from './components/SearchTile'
import { Layout } from 'antd';

const { Footer, Content } = Layout;
const DEFAULT_BACKGROUND_URL = "https://wallpaper.kikkaai.com/all.php";


class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            width: props.width || -1,
            height: props.height || -1,
            index: new Date().getMilliseconds(),
            isEdit: false,
            config: null,
            tiles: []
        };

        let mode = this.getQueryString("mode");
        mode = mode? mode: "www";

        fetch("data/" + mode + ".json")
        .then((res) => {
            return res.json();
        })
        .then((json_data) => {
            this.initConfig(json_data);
            this.makeTiles(json_data);
        })
        .catch((e) => {
            fetch("data/www.json")
            .then((res) => {
                return res.json();
            })
            .then((json_data) => {
                console.log(json_data);
                this.initConfig(json_data);
                this.makeTiles(json_data);
            })
            .catch((e) => {
                console.log(e.message);
            });
        });
    }

    onEdit = (checked, event) => {
        this.setState({
            isEdit: checked
        })
    };

    getQueryString(name) { 
        var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i"); 
        var r = window.location.search.substr(1).match(reg); 
        if (r != null) return unescape(r[2]); 
        return null; 
    } 

    initConfig (json_data) {
        let config = json_data["config"];
        let all = config.hasOwnProperty("all")? config["all"]: false;
        this.setState({
            config: {
                "all": all,
                "show_ICP": config["show_ICP"],
                "background": config["background"],
                "border_width": config["border_width"],
                "border_height": config["border_height"],
                "cell_box_w": config["cell_box_w"],
                "cell_box_h": config["cell_box_h"],
                "cell_w": config["cell_w"],
                "cell_h": config["cell_h"],
                "cell_row": config["cell_row"],
                "cell_column": config["cell_column"],
                "cell_space_w": config["cell_space_w"],
                "cell_space_h": config["cell_space_h"]
            }
        });
    }

    makeTiles (json_data) {
        let cells = json_data["cells"];
        let tiles = [];
        // console.log(cells);
        for (let i=0; i < cells.length; i++) {
            if(cells[i].type==="tile") {
                tiles.push(
                    <Tile
                        key={cells[i].id}
                        isEdit={this.state.isEdit}

                        x={cells[i].x}
                        y={cells[i].y}
                        w={cells[i].w}
                        h={cells[i].h}
                        color={cells[i].bgcolor}
                        title={cells[i].title}
                        url={cells[i].url}
                        icon={cells[i].icon}
                    />);
            } else if (cells[i].type==="search") {
                tiles.push(
                    <SearchTile
                        key={cells[i].id}
                        x={cells[i].x}
                        y={cells[i].y}
                        w={cells[i].w}
                        h={cells[i].h}
                        engine={cells[i].engine}
                    />);
            }

        }
        this.setState({
            tiles: tiles
        });
    }

    createImage(options) {
        options = options || {};
        const img = document.createElement("img");
        if (options.src) {
            img.src = options.src;
        }
        return img;
    };

    async copyToClipboard(pngBlob) {
        try {
            await navigator.clipboard.write([
                // eslint-disable-next-line no-undef
                new ClipboardItem({
                    [pngBlob.type]: pngBlob
                })
            ]);
            console.log("Image copied");
        } catch (error) {
            console.error(error);
        }
    };

    convertToPng(imgBlob) {
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");
        const imageEl = this.createImage({ src: window.URL.createObjectURL(imgBlob) });
        imageEl.onload = (e) => {
            canvas.width = e.target.width;
            canvas.height = e.target.height;
            ctx.drawImage(e.target, 0, 0, e.target.width, e.target.height);
            canvas.toBlob(this.copyToClipboard, "image/png", 1);
        };
    };

    async copyImg (src) {
        console.log(src);
        const img = await fetch(src);
        const imgBlob = await img.blob();
        const extension = src.split(".").pop();
        const supportedToBeConverted = ["jpeg", "jpg", "gif"];
        if (supportedToBeConverted.indexOf(extension.toLowerCase())) {
            return this.convertToPng(imgBlob);
        } else if (extension.toLowerCase() === "png") {
            return this.copyToClipboard(imgBlob);
        }
        console.error("Format unsupported");
        return;
    };


    render(){
        let config = this.state.config;
        if (!this.state.config)
        {
            return (<div />);
        }

        let background_url = config["background"];
        if (background_url === "")
        {
            background_url = DEFAULT_BACKGROUND_URL;
        }
        background_url +=  "?index=" + this.state.index;
        let redirect_url = background_url + "&redirect=true";
        let source_url = background_url + "&source=true";
        let background_image = "url(" + redirect_url +")";

        let cells = [];
        let box_w = config["cell_column"] * (config["cell_w"] + config["cell_space_w"]) - config["cell_space_w"];
        let box_h = config["cell_row"] * (config["cell_h"] + config["cell_space_h"]) - config["cell_space_h"];
        if (this.state.isEdit) {
            for (let j = 0; j < config["cell_row"]; j++) {
                for (let i = 0; i < config["cell_column"]; i++) {
                    cells.push(<Cell x={i} y={j}/>)
                }
            }
        }

        return (
            <Layout className="layout-box" style={{backgroundImage: background_image}}>
                <Content style={{minWidth: box_w, minHeight: box_h}}>
                    <div className="content" style={{minWidth: box_w, minHeight: box_h}}>
                        <div className="cells-box" style={{minWidth: box_w, minHeight: box_h}}>
                            {cells}
                            {this.state.tiles}
                        </div>
                    </div>
                </Content>
                <Footer style={{textAlign: "center", fontSize: 6}}>
                    by A.A 
                    | <a href={redirect_url} target="_blank" rel="noopener noreferrer">Wallpaper</a>
                    &nbsp;
                    | <a href={source_url} target="_blank" rel="noopener noreferrer">Source</a>
                    &nbsp;
                    | <a id="btn" onClick={() => this.copyImg(redirect_url)}>Copy</a>
                    &nbsp;
                    { (config["show_ICP"])?( <span > | <a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank" rel="noopener noreferrer">粤ICP备15099650号</a></span>):("")}
                    &nbsp;
                    | Copyright © 2019-now 
                </Footer>
            </Layout>
        )
    };
}

export default App;
