import Cell from './Cell'
import React from "react";
import { Avatar } from 'antd';

class Tile extends Cell {
    constructor(props) {
        super(props);
        this.state = {
            isEdit: props.isEdit || false,
            x: props.x,
            y: props.y,
            w: props.w || 1,
            h: props.h || 1,
            color: props.color,
            icon: props.icon,
            title: props.title,
            url: props.url
        }
    }

    lostIcon = () => {
        this.setState({icon: "/favicon.ico"});
        return true;
    };

    render() {
        let rect = this.getRect();
        return (
            <a
                draggable={this.state.isEdit}
                target="_blank"
                rel="noopener noreferrer"
                href={this.state.url}
            >
                <div
                    className="tile"
                    style={{
                        left: rect.left,
                        top: rect.top,
                        width: rect.width,
                        height: rect.height,
                        backgroundColor: this.state.color,
                    }}
                >
                    {/*<img className="tile-icon" src={this.state.icon} draggable="false"/>*/}
                    <Avatar shape="square" src={this.state.icon} className="tile-icon" onError={this.lostIcon}/>
                    <span className="tile-title">{this.state.title}</span>
                </div>
            </a>
        )
    }
}

export default Tile;